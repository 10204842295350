import styled from 'styled-components';

export const InternContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d4edf4;
`;

export const InternWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const InternContent = styled.div`
  display: flex;
  border-radius: 4px;
  gap: 20px;
  margin-top: 0.5rem;
  margin-bottom: 3rem;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 0;
  }
`
export const InternContentInfo = styled.div`
  display: block;
  padding: 100px 20px 50px 50px;
  width: 50%;

  @media screen and (max-width: 1265px) {
    width: 48%;
    padding: 80px 20px 50px 50px;
  }

  @media screen and (max-width: 1220px) {
    width: 44%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 100px 20px 0 20px;
  }

  @media screen and (max-width: 500px) {
    padding: 50px 20px 0 20px;
  }
`
export const H2 = styled.h2`
  font-weight: bold;
`
export const P = styled.p`
  display: block;
`

export const InternContentCard = styled.div`
  ${'' /* margin: 10px 50px; */}
  ${'' /* padding-bottom: 50px; */}
  font-size: 1rem;
  ${'' /* display: flex; */}
  width: 45%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 4px;
  margin-top: 0.5rem;
  gap: 50px;
`;

export const InternCard = styled.div`
  padding: 5px 20px;
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  background-color: #fff;
  font-size: 1rem;
  box-shadow: 0 0 26px rgba(9, 9, 9, 0.3);
  box-sizing: border-box;
  border-radius: 30px;
  line-height: 20px;
  resize: vertical;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  &::placeholder {
    color: #bbb;
  }

  @media screen and (max-width: 1265px) {
    width: 110%;
  }

  @media screen and (max-width: 1220px) {
    width: 115%;
  }
  
  @media screen and (max-width: 768px) {
    width: 200%;
    margin-left: 20px;
  }

  @media screen and (max-width: 560px) {
    flex-direction: column;
    align-items: start;
    padding: 20px;
  }
`;

export const H4 = styled.h4 `
  margin-top: 30px;
  margin-left: 50px;
  margin-right: 10px;

  @media screen and (max-width: 940px) {
    margin-left: 20px;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 20px 10px 20px;
    margin: 0;
  }

  @media screen and (max-width: 431px){
    font-size: 15px;
    margin-top: 20px;
  }
`

export const Button = styled.button`
    border-radius: 15px;
    width: fit-content;
    background: transparent;
    border: 2px solid black;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#010606' : '#000')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    font-weight: 600;
    margin-left: 50px;
    margin-top: 30px;

    &:hover {
        background: ${({primary}) => (primary ? '#fff' : '#020202')};
        color: ${({dark}) => (dark ? '#010606' : '#fff')};
    }

  @media screen and (max-width: 940px) {
    margin-left: 20px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 20px;
    margin-top: 20px;
  }

  @media screen and (max-width: 431px){
    font-size: 15px;
  }
`

export const ButtonV = styled.button`
    border-radius: 15px;
    width: fit-content;
    background: transparent;
    border: 2px solid #e0703b;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#010606' : '#e0703b')};
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    font-weight: 600;
    margin-top: 30px;

    &:hover {
        background: ${({primary}) => (primary ? '#fff' : '#e0703b')};
        color: ${({dark}) => (dark ? '#010606' : '#000')};
    }

  @media screen and (max-width: 768px) {
    margin-top: 20px;
    margin-left: 20px;
  }

  @media screen and (max-width: 431px){
    font-size: 15px;
  }
`