import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  SigninContainer,
  SigninWrapper,
  SigninP,
  SigninInput,
  MapsContainer,
  MapsWrapper,
  MapsInfo,
  A,
  Iframe,
  Sales,
  Compliance
} from "./SigninElements";
import ContactImage from "../../images/contact.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "../ButtonElements";
import "../../App.css";
import AddImage from "../../images/map.jpg";

const Signin = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    message: "",
  });

  const [recaptchaToken, setRecaptchaToken] = useState("");

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!recaptchaToken) {
      alert("Please verify you are not a robot!");
      return;
    }

    const sanitizedFormData = {
      firstName: sanitizeInput(formData.firstName),
      lastName: sanitizeInput(formData.lastName),
      phoneNumber: sanitizeInput(formData.phoneNumber),
      email: sanitizeInput(formData.email),
      message: sanitizeInput(formData.message),
    };

    axios.post("../../backend/submit_form.php", sanitizedFormData)
      .then((response) => {
        if (response.data.success) {
          alert(response.data.message);
        } else {
          alert("Error: " + response.data.message);
        }
      })
      .catch((error) => {
        alert("Error: " + error.message);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRecaptchaVerify = (token) => {
    setRecaptchaToken(token);
  };

  const sanitizeInput = (input) => {
    return input.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  };

  return (
    <SigninContainer>
      <SigninWrapper>
        <div className="my-auto">
          <h3 className="mb-4 con">Looking for a different solution?</h3>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="firstName"></label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="Enter first name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName"></label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Enter last name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber"></label>
              <input
                type="tel"
                className="form-control"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                placeholder="Enter phone number"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email"></label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter email"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message"></label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                rows="3"
                value={formData.message}
                onChange={handleChange}
                placeholder="Enter message"
                required
              ></textarea>
            </div>
            <div
              className="g-recaptcha"
              data-sitekey="6LdC9wYqAAAAACHKTsx-kLjZo6LDS91kbUQV8Jbf"
              data-callback={handleRecaptchaVerify}
              style={{ margin: "10px 0" }}
            />
            <Button type="submit">
              Let's Connect
            </Button>
          </form>
        </div>
        <img src={ContactImage} alt="" style={{ width: "auto", height: "60vh" }} className="contacti" />
        {/* <div style={{display: "flex", flexDirection: "column", justifyContent: "center", gap: "50px", alignItems: "center", backgroundColor: "#F5D4C4", padding: "50px 20px", margin: "auto", borderRadius: "20px"}}>
        <A href="mailto: sales@xpertlyte.com">sales@xpertlyte.com</A>
        <A href="mailto: compliance@xpertlyte.com">compliance@xpertlyte.com</A>
        <A href="mailto: support@xpertlyte.com">support@xpertlyte.com</A>
      </div> */}
      </SigninWrapper>
    <MapsContainer>
      <h2 style={{textAlign: 'center', marginBottom: '50px'}}>Our location</h2>
      <MapsWrapper>
        <Iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.891144115615!2d73.0107882!3d19.0788356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c168b615608d%3A0xc4724ace45d87091!2sAkshar%20Business%20Park!5e0!3m2!1sen!2sin!4v1643723431151!5m2!1sen!2sin"
          width="100%"
          height="500"
          allowFullScreen=""
          loading="lazy"
        >
        </Iframe>
        <MapsInfo>
            <img src={AddImage} style={{width: '100%', height: '250px', borderRadius: '30px', marginBottom: '20px'}}></img>
            <h3>HEADQUARTER</h3>
            <p>Akshar Business Park, A-1004, Sector 25, Vashi, Navi Mumbai, Maharashtra 400703</p>
            <A href="tel:+91 8879934521">Call Now</A>
        </MapsInfo>
      </MapsWrapper>
    </MapsContainer>
    </SigninContainer>
  );
};

export default Signin;